@tailwind base;
@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

@tailwind utilities;

html, body {
  background: #F8F8F8;
  color: #424242;
  -webkit-tap-highlight-color: transparent;
  overflow-y: scroll;
  overflow-y: overlay;

  @apply antialiased 
}

main {
  min-height: calc(100vh - 5em);

  @apply animate-fadeIn
}

::selection {
  color: #F8F8F8;
  background: #424242;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

input:-webkit-autofill:disabled,
input:-webkit-autofill:focus textarea:-webkit-autofill:disabled,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:disabled,
select:-webkit-autofill:disabled{
  -webkit-box-shadow: 0 0 0px 1000px #ededed inset !important;
}